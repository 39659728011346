export default [
  {
    header: 'Ordini',
    title: 'Ordini',
    icon: 'DownloadIcon',
    route: 'ordini.list',
    resource: 'Orders',
    action: 'index',
  },
]
