export default [
  {
    header: 'Provvigioni',
    title: 'Provvigioni',
    icon: 'ActivityIcon',
    route: 'provvigioni.list',
    resource: 'Vpcs',
    action: 'index',
  },
]
