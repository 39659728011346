export default [
  {
    header: 'Commissioni',
    title: 'Commissioni',
    icon: 'DivideIcon',
    route: 'commissioni-richieste.list',
    resource: 'Commissions',
    action: 'index',
  },
]
