export default [
  {
    header: 'Prodotti',
    title: 'Prodotti',
    icon: 'PackageIcon',
    route: 'prodotti.list',
    resource: 'Items',
    action: 'store',
  },
]
