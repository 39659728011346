export default [
  {
    title: 'Iscritti',
    icon: 'UserIcon',
    children: [
      {
        title: 'Iscritti',
        route: 'iscritti.list',
        icon: 'UserIcon',
        resource: 'Users',
        action: 'index',
      },
      {
        title: 'Albero iscritti',
        route: 'iscritti.tree',
        icon: 'UsersIcon',
        resource: 'Users',
        action: 'index',
      },
      {
        route: 'iscritti.store',
      },
      {
        route: 'iscritti.edit',
      },
    ],
  },
]
